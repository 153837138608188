var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-settings app-fab--absolute" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            id: "app-settings-dd",
            "popper-opts": _vm.popper,
            variant: "primary rounded-circle",
            "toggle-class": "mdc-fab material-icons",
            "no-caret": "",
            dropup: "",
            right: !_vm.settings["layout.rtl"]
          }
        },
        [
          _c("template", { slot: "button-content" }, [
            _c("span", { staticClass: "mdc-fab__icon" }, [
              _vm._v("\n        settings\n      ")
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.options, function(option) {
            return _c(
              "collapse",
              {
                key: "collapse-" + option.id,
                attrs: { id: option.id, title: option.title }
              },
              _vm._l(option.children, function(group, index) {
                return _c(
                  "b-form-group",
                  {
                    key: "group-" + option.id + "-" + index,
                    class: { "mb-0": index === option.children.length - 1 },
                    attrs: { label: group.title }
                  },
                  [
                    group.component === "form-image-group"
                      ? _c("form-image-group", {
                          attrs: { options: group.options, name: group.id },
                          model: {
                            value: _vm.settings[option.id + "." + group.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.settings,
                                option.id + "." + group.id,
                                $$v
                              )
                            },
                            expression: "settings[`${option.id}.${group.id}`]"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    group.component === "b-form-radio-group"
                      ? _c("b-form-radio-group", {
                          attrs: {
                            id: "group-" + option.id + "-" + index,
                            options: group.options
                          },
                          model: {
                            value: _vm.settings[option.id + "." + group.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.settings,
                                option.id + "." + group.id,
                                $$v
                              )
                            },
                            expression: "settings[`${option.id}.${group.id}`]"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    group.component === "b-form-select"
                      ? _c("b-form-select", {
                          attrs: {
                            id: "group-" + option.id + "-" + index,
                            options: group.options
                          },
                          model: {
                            value: _vm.settings[option.id + "." + group.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.settings,
                                option.id + "." + group.id,
                                $$v
                              )
                            },
                            expression: "settings[`${option.id}.${group.id}`]"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    group.component === "b-form-checkbox"
                      ? _c(
                          "b-form-checkbox",
                          {
                            attrs: { id: "group-" + option.id + "-" + index },
                            model: {
                              value: _vm.settings[option.id + "." + group.id],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.settings,
                                  option.id + "." + group.id,
                                  $$v
                                )
                              },
                              expression: "settings[`${option.id}.${group.id}`]"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(group.title) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    group.component === "custom-checkbox-toggle"
                      ? _c(
                          "b-form-checkbox",
                          {
                            staticClass:
                              "custom-checkbox-toggle custom-control-inline mr-1",
                            attrs: { id: "group-" + option.id + "-" + index },
                            model: {
                              value: _vm.settings[option.id + "." + group.id],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.settings,
                                  option.id + "." + group.id,
                                  $$v
                                )
                              },
                              expression: "settings[`${option.id}.${group.id}`]"
                            }
                          },
                          [_vm._v(" ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    group.component === "custom-checkbox-toggle"
                      ? _c(
                          "label",
                          {
                            staticClass: "mb-0",
                            attrs: { for: "group-" + option.id + "-" + index }
                          },
                          [
                            _c("span", { staticClass: "form-label m-0" }, [
                              _vm._v(_vm._s(group.title))
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              1
            )
          }),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.debug
            ? _c("pre", { domProps: { textContent: _vm._s(_vm.settings) } })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }